import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        count: 0
    },
    mutations: {
        
        increment(state) {
            state.count++;
        },
        decrement(state) {
            state.count--;
        }
    },
    actions: {
        increment(context) {
            context.commit('increment');
        },
        decrement(context) {
            context.commit('decrement');
        },
        arrGroup(array, sublength) {
            let index = 0;
            let newArray = []
            while (index < array.length) {
                newArray.push(array.slice(index, index += sublength))
            }
            return newArray
        },
    },
    getters: {
        getCount: state => {
            return state.count;
        },
        
    }
});

export default store;