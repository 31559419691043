import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/css/animate.css"

import ElementUI from 'element-ui';

Vue.use(ElementUI);



import store from './store/store.js';


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) },
  mounted () {
    document.dispatchEvent(new Event('render-event'))
    
  },
}).$mount('#app')


// 缩小屏幕 webpack报错
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

