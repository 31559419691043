document.writeln('<div id="newcefu">\n' +
    '    <ul>\n' +
    // '        <li class="lx-zixun"><a href="https://tb.53kf.com/code/client/10168353/1" target="_blank"></a></li>\n' +
    // '        <li class="fy-zixun"><a href="https://tb.53kf.com/code/client/10168353/1" target="_blank"></a></li>\n' +
    '        <li class="lx-zixun"><a href="javascript:void(0)" onclick="ydtopenchat()"></a></li>\n' +
    '        <li class="fy-zixun"><a href="javascript:void(0)" onclick="ydtopenchat()"></a></li>\n' +
    '        <li class="wx-zixun">\n' +
    '            <div class="xiaoxiannv">\n' +
    '                <img src="https://public.rossoarts.com/static/images/weixin.jpg">\n' +
    '            </div>\n' +
    '        </li>\n' +
    '        <li id="go-top"></li>\n' +
    '    </ul>\n' +
    '</div>');

$(function() {
    //鐐瑰嚮璺宠浆閾炬帴锛屾粴鍔ㄦ潯璺冲埌0鐨勪綅缃紝椤甸潰绉诲姩閫熷害鏄�1000
    $("#go-top").click(function() {
        // $('html,body').animate({
        //     scrollTop: '1'
        // }, 1000);
        // window.scrollTo(0,0)
        return false; //闃叉榛樿浜嬩欢琛屼负
    })
})