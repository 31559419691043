<template>
    <div class="section_inp">
        <div class="s_inp_left">
            <img src="../img/shouchahua.png" alt="" class="img_shou  wow rotateInDownLeft" id="imgShou">
            <img src="../img/Group 243.png" alt="" class="img_text  wow rollIn" id="imgText">
        </div>
        <div class="s_inp_right">
            <el-form :inline="true" :label-position="labelPosition" label-width="150px" :model="formLabelAlign">
                <el-form-item label="姓名：">
                    <el-input v-model="formLabelAlign.name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="预约校区：" class="elitem2">
                    <el-select v-model="formLabelAlign.region" placeholder="选择预约校区">
                        <el-option label="上海" value="shanghai"></el-option>
                        <el-option label="北京" value="beijing"></el-option>
                        <el-option label="南京" value="nanjing"></el-option>
                        <el-option label="成都" value="chengdu"></el-option>
                        <el-option label="杭州" value="hangzhou"></el-option>
                        <el-option label="广州" value="guangzhou"></el-option>
                        <el-option label="深圳" value="shenzhen"></el-option>
                        <el-option label="武汉" value="wuhan"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电话：">
                    <el-input v-model="formLabelAlign.tel" placeholder="请输入电话号码"></el-input>
                </el-form-item>
                <el-form-item label="意向国家：" class="elitem2">
                    <el-select v-model="formLabelAlign.region2" placeholder="选择意向国家">
                        <el-option label="英国" value="England"></el-option>
                        <el-option label="美国" value="America"></el-option>
                        <el-option label="日本" value="Japan"></el-option>
                        <el-option label="新加坡" value="Singapore"></el-option>
                        <el-option label="韩国" value="Korea"></el-option>
                        <el-option label="欧洲" value="Europe"></el-option>
                      
                        <el-option label="其他" value="Other"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="意向专业：">
                    <el-input v-model="formLabelAlign.major" placeholder="请输入意向专业"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="yuyue" @click="onSubmit()"></div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { WOW } from 'wowjs'
import axios from "axios"
export default {
    data() {
        return {
            formLabelAlign: {
                name: '',
                tel: '',
                region: '',
                region2: '',
                major: '',
            },
            labelPosition: 'right',
        }
    },
    methods: {
        onSubmit() {
           
            var currentURL = window.location.href;
            var status = ''
            // 检查 URL 中是否包含某些关键词，来判断使用的搜索引擎
            if (currentURL.includes("google")) {
                status = "用户正在使用 Google 搜索引擎"
            } else if (currentURL.includes("bing")) {
                status = "用户正在使用 Bing 搜索引擎"
            } else if (currentURL.includes("yahoo")) {
                status = "用户正在使用 Yahoo 搜索引擎"
            } else if (currentURL.includes("baidu")) {
                status = "用户正在使用 百度 搜索引擎"
            } else {
                status = "无法确定用户使用的搜索引擎"
            }



            var patt = /^1[3-9][0-9]{9}$/

            if (this.formLabelAlign.name == "" || this.formLabelAlign.tel == "") {
                // console.log("姓名和手机号不能为空");
                this.$message.error('姓名和手机号不能为空');

            } else {
                // console.log("不是空");

                if (patt.test(this.formLabelAlign.tel)) {

                    // console.log("可以发请求",status);
                    // console.log(this.formInline);
                    axios.post("https://public.rossoarts.com/index/common/getform", {
                        "name": this.formLabelAlign.name,
                        "tel": this.formLabelAlign.tel,
                        "major": this.formLabelAlign.major,
                        "select_campus":this.formLabelAlign.region,
                        "intended_country":this.formLabelAlign.region2,
                        "url": window.location.href,
                        "status":status,
                    }).then((data) => {
                        console.log(data, "response");
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                    }).catch(function (error) {
                        console.log(error, "error");
                    });

                } else {
                    console.log("手机号格式不正确");
                    this.$message.error('手机号格式不正确');

                }

            }


        },
    }
}
</script>